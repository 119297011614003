"use client";

import Image from "next/image";
import img from "../../images/chevron_left.svg";
import { useRouter } from "next/navigation";

const GoHomeRedirection = () => {
  const router = useRouter();

  return (
    <div className="flex sm:hidden items-start mb-[24px] sm:mb-0">
      <a
        onClick={() => {
          window.history.length === 0 ? (window.location.href = "/") : router.back();
        }}
        className="text-primary text-[16px] font-600 tracking-[-0.2px] leading-[24px] flex flex-row items-center cursor-pointer"
      >
        <Image priority={true} src={img} alt={"go back"} color="#2e61ef" className="fill-primary" />
        Go back to previous page
      </a>
    </div>
  );
};

export default GoHomeRedirection;
