export const seonConfig = (sessionId: string) => {
  return new Promise<string>((resolve, reject) => {
    window.seon.config({
      session_id: sessionId,
      audio_fingerprint: true,
      canvas_fingerprint: true,
      webgl_fingerprint: true,
      onSuccess: function (message) {
        resolve(message);
      },
      onError: function (message) {
        reject(message);
      }
    });
  });
};

export const seonBase64Session = () => {
  return new Promise<string>((resolve, reject) => {
    window.seon.getBase64Session((data) => {
      if (data) {
        resolve(data);
      } else {
        reject();
      }
    });
  });
};
