export function setLocalStorageUniqueSession(sessionId: string) {
  if (typeof localStorage !== "undefined") {
    localStorage.setItem("iCashUniqueSession", sessionId);
  }
}

export function getLocalStorageUniqueSession() {
  if (typeof localStorage !== "undefined") {
    return localStorage.getItem("iCashUniqueSession");
  }
  return null;
}

export function removeLocalStorageUniqueSession() {
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem("iCashUniqueSession");
  }
}
