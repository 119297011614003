import { UserInfoType } from "@helpers/requests/types/auth";

export const setAuthCookie = (isAuth: boolean) => {
  const newDate = new Date();

  const newDate60MoreMinutes = new Date(newDate.getTime() + 30 * 60000); // 30min session

  if (typeof document !== "undefined") {
    document.cookie = `auth=${isAuth}; expires=${newDate60MoreMinutes.toUTCString()}; path=/; samesite=Lax; Secure`;
  }
};

export function setUserNameCookie(user: UserInfoType) {
  let uname = [user.firstName?.trim() || "", (user.lastName?.trim() || "")[0]].join(" ");

  const date = new Date();
  date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);

  document.cookie = `iCashUserName=${encodeURIComponent(
    uname
  )}; expires=${date.toUTCString()}; path=/; samesite=Lax; Secure`;
}
