import { createContext, Dispatch, useReducer } from "react";

const searchParamsState = {
  searchParams: undefined
};

export type SearchParamsActionType = {
  type: "setSearchParams";
  searchParams?: { [key: string]: string | string[] | undefined };
};

type SearchParamsStateType = {
  searchParams?: { [key: string]: string | string[] | undefined };
};

const searchParamsReducer = (state: SearchParamsStateType, action: SearchParamsActionType) => {
  switch (action.type) {
    case "setSearchParams":
      return { searchParams: action.searchParams };
    default:
      return { ...state };
  }
};

export const useSearchParamsReducer = () => useReducer(searchParamsReducer, searchParamsState);

export const SearchParamsContext = createContext<{
  state: SearchParamsStateType;
  dispatch: Dispatch<SearchParamsActionType>;
}>({
  state: { searchParams: undefined },
  dispatch: () => {}
});
