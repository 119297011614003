import * as rdd from "react-device-detect";

export const detectBrowserName = () => {
  return rdd.browserName;
};

export const detectDeviceVendor = () => {
  return rdd.mobileVendor;
};

export const detectOS = () => {
  return rdd.osName;
};

export const detectOSVersion = () => {
  return rdd.osVersion;
};

export const detectDeviceType = () => {
  return rdd.deviceType;
};

export const detectBrowserVersion = () => {
  return rdd.browserVersion;
};

export const detectModel = () => {
  return rdd.mobileModel;
};

export const getScreenDimension = () => {
  if (typeof window !== "undefined") {
    const w = window.innerWidth;
    const h = window.innerHeight;

    return `${w}x${h}`;
  }
  return ``;
};

export const getOrientation = () => {
  if (typeof window !== "undefined") {
    if (window.matchMedia("(orientation: portrait)").matches) {
      return "portrait";
    }

    if (window.matchMedia("(orientation: landscape)").matches) {
      return "landscape";
    }
  }
  return null;
};

export const getDeviceData = (ip: string | null, seonDeviceFingerprint: string | null) => {
  return {
    os: detectOS(),
    ip: ip,
    osVersion: detectOSVersion(),
    model: detectModel() === "none" ? null : detectModel(),
    name: detectDeviceVendor() === "none" ? null : detectDeviceVendor(),
    screenDimension: getScreenDimension(),
    type: detectDeviceType(),
    orientation: getOrientation(),
    uuid: "",
    userAgent: navigator.userAgent,
    browserName: detectBrowserName(),
    browserVersion: detectBrowserVersion(),
    seonDeviceFingerprint: seonDeviceFingerprint
  };
};
