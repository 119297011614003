import { setAuthCookie } from "@helpers/cookies/auth";
import { removeLocalStorageLogout } from "@helpers/storages/auth";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";

export const baseUrl = process.env.NEXT_PUBLIC_MARIA_BASE_URL;

export const mariaFetch = async (
  input: RequestInfo | URL,
  init?: RequestInit | undefined,
  redirection?: AppRouterInstance
): Promise<Response> => {
  if (!baseUrl) {
    console.error(`NEXT_PUBLIC_MARIA_BASE_URL is not set.`);
  }

  const initRequest: RequestInit = init
    ? {
        credentials: process.env.NEXT_PUBLIC_SANDBOX_ENABLE === "true" ? "include" : undefined,
        ...init
      }
    : {
        credentials: process.env.NEXT_PUBLIC_SANDBOX_ENABLE === "true" ? "include" : undefined
      };

  const url = `${baseUrl}${input}`;
  const response = await fetch(url, initRequest);

  if (response.status === 401 && redirection) {
    setAuthCookie(false);
    removeLocalStorageLogout();
    redirection.push("/login");
  }

  if (response.ok && redirection) {
    setAuthCookie(true);
  }

  return response;
};
