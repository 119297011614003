import LogRocket from "logrocket";
import { UserInfoType } from "./requests/types/auth";

let isInit = false;
let isAuth = false;

export function useLogRocket() {
  const init = () => {
    if (process.env.NEXT_PUBLIC_LOG_ROCKET_LOG_ID && !isInit) {
      LogRocket.init(process.env.NEXT_PUBLIC_LOG_ROCKET_LOG_ID, {
        mergeIframes: true,
        childDomains: [
          process.env.NEXT_PUBLIC_CONTRACT_SIGNATURE_URL ?? "",
          process.env.NEXT_PUBLIC_SELENE_URL ?? "",
          process.env.NEXT_PUBLIC_REPAYMENT_PLAN_URL ?? "",
          process.env.NEXT_PUBLIC_EQUIFAX_URL ?? ""
        ],
        dom: {
          baseHref: process.env.NEXT_PUBLIC_ICASH_DASHBOARD_BASE_URL
        }
      });
      isInit = true;
    } else {
      console.warn("NEXT_PUBLIC_LOG_ROCKET_LOG_ID not set.");
    }
  };

  const identify = (user: UserInfoType | undefined, force?: boolean) => {
    if (!isInit) {
      init();
    }

    if (isInit && (!isAuth || force === true) && user) {
      LogRocket.identify(user?.contactId?.toString() ?? "", {
        email: user?.email ?? ""
      });
      isAuth = true;
    }

    if (!user) {
      isAuth = false;
    }
  };

  return { identify, init };
}
