export const Label = (props: { for?: string; children: JSX.Element | string; className?: string }) => {
  return (
    <label
      htmlFor={props.for}
      className={`text-interface-200 text-[14px] leading-[20px] tracking-[-0.3px] font-500 ${props.className}`}
    >
      {props.children}
    </label>
  );
};
