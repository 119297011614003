import { createContext, Dispatch, useReducer } from "react";
import { InstantLoanDashboard } from "@helpers/requests/types/instant-loan-dashboard";

const InstantLoanState = {
  instantLoan: null
};

export type InstantLoanActionType =
  | {
      type: "setInstantLoan";
      value: InstantLoanDashboard;
    }
  | {
      type: "reset";
    };

type InstantLoanInitialStateType = {
  instantLoan: InstantLoanDashboard | null;
};

const instantLoanReducer = (state: InstantLoanInitialStateType, action: InstantLoanActionType) => {
  switch (action.type) {
    case "setInstantLoan":
      return {
        instantLoan: action.value
      };
    case "reset":
      return {
        instantLoan: null
      };
    default:
      return { ...state };
  }
};

export const useInstantLoanReducer = () => useReducer(instantLoanReducer, InstantLoanState);

export const InstantLoanContext = createContext<{
  state: InstantLoanInitialStateType;
  dispatch: Dispatch<InstantLoanActionType>;
}>({
  state: { instantLoan: null },
  dispatch: () => {}
});
