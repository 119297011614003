import * as React from "react";

import { SidePanel } from "@brainfinance/icash-component-library";
import { SupportPanelProps } from "./support-panel";

import "./style.css";
import { useInstantLoanHooks } from "@helpers/hooks/instantLoan";

export const SupportPanel = (props: SupportPanelProps) => {
  const { state } = useInstantLoanHooks(undefined, true);
  return (
    <>
      <SidePanel active={props.isOpen} onOpen={props.handleOpen} onClose={props.handleClose} id='test-support-panel'>
        <h2 className="title-caption py-[32px] pr-[32px] sm:pr-[16px]">Support</h2>
        <p className="caption-light mt-[44px]">
          iCash is a technology-based company that focuses on offering faster and smarter loans. We offer our customer
          support exclusively by email and text. Our customer experience agents are responding to emails 24/7 and will
          be happy to help. Send us an email at{" "}
          <a href={state?.instantLoan?.status === "pendingSignature" ? "mailto:application@icash.ca" : "mailto:support@icash.ca"} className="text-primary font-600">
            {state?.instantLoan?.status === "pendingSignature" ? "application@icash.ca" : "support@icash.ca"}
          </a>
          .
        </p>
      </SidePanel>
    </>
  );
};
