import {
  LoginMFARequestType,
  LoginRequestType,
  MFAResendEmailRequestType,
  MFAResendSmsRequestType
} from "./types/auth";
import { mariaFetch } from "./maria-fetch";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";

export async function postSignIn(
  email: string,
  password: string,
  ambassadorCode?: string,
  redirection?: AppRouterInstance
) {
  const response = await mariaFetch(
    "/user/v2/login",
    {
      method: "POST",
      headers: {
        "Application-ID": process.env.NEXT_PUBLIC_MARIA_APPLICATION_ID,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ email, password, ambassadorCode })
    },
    redirection
  );

  if (response.status === 202) return (await response.json()) as LoginMFARequestType;
  return (await response.json()) as LoginRequestType;
}

export async function postConfirmMFALogin(challengeId: string, otp: string, redirection?: AppRouterInstance) {
  const response = await mariaFetch(
    "/user/v2/loginconfirm",
    {
      method: "POST",
      headers: {
        "Application-ID": process.env.NEXT_PUBLIC_MARIA_APPLICATION_ID,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ challengeId, otp })
    },
    redirection
  );
  return (await response.json()) as LoginRequestType;
}

export async function postResendMFASms(challengeId: string, redirection?: AppRouterInstance) {
  const response = await mariaFetch(
    "/user/v2/loginresendchallengesms",
    {
      method: "POST",
      headers: {
        "Application-ID": process.env.NEXT_PUBLIC_MARIA_APPLICATION_ID,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ challengeId })
    },
    redirection
  );
  return (await response.json()) as MFAResendSmsRequestType;
}
export async function postResendMFAEmail(challengeId: string, redirection?: AppRouterInstance) {
  const response = await mariaFetch(
    "/user/v2/loginresendchallengeemail",
    {
      method: "POST",
      headers: {
        "Application-ID": process.env.NEXT_PUBLIC_MARIA_APPLICATION_ID,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ challengeId })
    },
    redirection
  );
  return (await response.json()) as MFAResendEmailRequestType;
}

export async function postForgotPassword(email: string) {
  return mariaFetch("/user/forgotpassword", {
    method: "POST",
    headers: {
      "Application-ID": process.env.NEXT_PUBLIC_MARIA_APPLICATION_ID,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ email })
  });
}

export async function postResetPassword(password: string, confirmCode: string) {
  return mariaFetch("/user/forgotpasswordcompletion", {
    method: "POST",
    headers: {
      "Application-ID": process.env.NEXT_PUBLIC_MARIA_APPLICATION_ID,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ password, confirmCode })
  });
}

export async function postVerifyForgotPasswordCode(confirmCode: string) {
  return mariaFetch("/user/forgotpasswordcodevalidation", {
    method: "POST",
    headers: {
      "Application-ID": process.env.NEXT_PUBLIC_MARIA_APPLICATION_ID,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ confirmCode })
  });
}
