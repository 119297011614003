import { getLocalStorageUniqueSession, setLocalStorageUniqueSession } from "./storages/session";
import { seonBase64Session, seonConfig } from "@helpers/seon";
import crypto from "crypto";
import { mariaFetch } from "./requests/maria-fetch";
import { getDeviceData } from "./user-agent-detection";

export async function updateDeviceData(token: string) {
  try {
    const response = await fetch("/app/api/ip");
    let ip: string | null = null;
    if (response.ok) {
      const data = (await response.json()) as { ip: string | null };
      ip = data.ip;
    }

    let uuid: null | string = getLocalStorageUniqueSession();
    if (!uuid) {
      try {
        uuid = crypto.randomUUID();
      } catch (error) {
        uuid = crypto.randomBytes(32).toString("hex");
      }
    }

    await seonConfig(uuid);
    const base64 = await seonBase64Session();

    await mariaFetch("/user/devicedata", {
      method: "POST",
      headers: {
        "Application-ID": process.env.NEXT_PUBLIC_MARIA_APPLICATION_ID,
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(getDeviceData(ip, base64))
    });

    setLocalStorageUniqueSession(uuid);
  } catch (error) {
    console.error("Device data cannot be sent", error);
  }
}
