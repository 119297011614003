"use client";

import {
  Button,
  isValidEmail,
  Modal,
  PasswordInput,
  PinInputV2,
  TextInput,
  ValidationMessage
} from "@brainfinance/icash-component-library";
import { Subheading } from "@components/typography";
import Lottie from "lottie-react";
import * as React from "react";
// Images
import { mariaFetch } from "@helpers/requests/maria-fetch";
import { getDeviceData } from "@helpers/user-agent-detection";
import { useSingularAnalytics } from "@helpers/useSingularAnalytics";
import SearchQueryProvider from "@components/search-query-provider";
import { SupportPanel } from "@components/support-panel";
import { useClevertapAnalytics } from "@helpers/clevertapAnalytics";
import { SearchParamsContext, useSearchParamsReducer } from "@helpers/context/searchParams";
import { setAuthCookie, setUserNameCookie } from "@helpers/cookies/auth";
import { updateDeviceData } from "@helpers/device-data";
import { useLogRocket } from "@helpers/logrocket";
import { postConfirmMFALogin, postResendMFAEmail, postResendMFASms, postSignIn } from "@helpers/requests/auth";
import { errorHandler } from "@helpers/stackDriver";
import { setLocalStorageUserSession } from "@helpers/storages/auth";
import iCashSymbolBlue from "@images/icash-symbol-blue.json";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { sendGTMEvent } from "@next/third-parties/google";

const InvalidEmailErrorMessage = "The field Email is not a valid email";
const RequiredEmailErrorMessage = "Please enter the email";
const RequiredPasswordErrorMessage = "Please enter the password";

interface InfoUserMFA {
  phoneNumber?: string;
  email: string;
  challengeId: string;
  ambassadorCode?: string;
}
type MFAType = "email" | "sms";
interface ConfigMessageMFA {
  type: "success" | "error";
  message: string;
}
interface MessageMFA {
  invalid: ConfigMessageMFA;
  expired: ConfigMessageMFA;
  maxAttempts: ConfigMessageMFA;
  resendSMS: ConfigMessageMFA;
  resendEmail: ConfigMessageMFA;
  unknown: ConfigMessageMFA;
}

const configErrorMFA: MessageMFA = {
  invalid: {
    type: "error",
    message: "Invalid code."
  },
  expired: {
    type: "error",
    message: "Code has expired. Please request a new code to continue."
  },
  maxAttempts: {
    type: "error",
    message: "The maximum number of verification code requests has been reached."
  },
  resendSMS: {
    type: "success",
    message: "An SMS has been resent to you."
  },
  resendEmail: {
    type: "success",
    message: "An email has been resent to you."
  },
  unknown: {
    type: "error",
    message: "Something went wrong. Please try again or contact us if the error persists."
  }
};

interface LoginFormProps {
  setInfoUserMfa: (value: InfoUserMFA | undefined) => void;
  setAmbassadorCode: (ambassadorCode: string | undefined) => void;
}
interface MFAConfirmationProps {
  setInfoUserMfa: (value: InfoUserMFA | undefined) => void;
  infoUserMFA: InfoUserMFA;
  ambassadorCode: string | undefined;
}
interface ModaleVerificationProps {
  infoUserMFA: InfoUserMFA | undefined;
  onClose: () => void;
  onResend: () => void;
}
export const LoginComponent = () => {
  const [infoUserMFA, setInfoUserMfa] = React.useState<InfoUserMFA>();
  const [ambassadorCode, setAmbassadorCode] = React.useState<string>();
  const [searchParamsState, searchParamsDispatch] = useSearchParamsReducer();

  return (
    <SearchParamsContext.Provider value={{ state: searchParamsState, dispatch: searchParamsDispatch }}>
      {searchParamsState.searchParams?.state !== "mfa" || !infoUserMFA ? (
        <LoginForm setInfoUserMfa={setInfoUserMfa} setAmbassadorCode={setAmbassadorCode} />
      ) : (
        <MFAConfirmation infoUserMFA={infoUserMFA} setInfoUserMfa={setInfoUserMfa} ambassadorCode={ambassadorCode} />
      )}
      <React.Suspense fallback={<></>}>
        <SearchQueryProvider />
      </React.Suspense>
    </SearchParamsContext.Provider>
  );
};

const LoginForm = (props: LoginFormProps) => {
  const [email, setEmail] = React.useState<string>();
  const [password, setPassword] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | undefined>();
  const { track, identify, page } = useSingularAnalytics();
  const [emailError, setEmailError] = React.useState<string | undefined>();
  const [passwordError, setPasswordError] = React.useState<string | undefined>();
  const { event, screen, profile } = useClevertapAnalytics();
  const logRocket = useLogRocket();
  const [showNewPasswordModal, setShowNewPasswordModal] = React.useState(false);
  const [showLinkHasExpiredModal, setShowLinkHasExpiredModal] = React.useState(false);
  const { state: { searchParams } } = React.useContext(SearchParamsContext)
  const router = useRouter();

  React.useEffect(() => {
    if (!searchParams) return;

    const ambassadorCode = searchParams["ambassadorCode"];
    const newPassword = searchParams["newPassword"];
    const expiredLink = searchParams["expiredLink"];

    if (ambassadorCode) {
      screen("account_login_loaded", {
        referralProgram: ambassadorCode ? true : false
      });
      page("Public", "account_login_loaded");
    }

    if (newPassword) {
      setShowNewPasswordModal(true);
    }

    if (!newPassword && expiredLink) {
      setShowLinkHasExpiredModal(true);
    }


  }, [searchParams]);

  const handleChangeEmail = (email?: string | number) => {
    setEmailError(undefined)
    if (email) setEmail(email.toString());
    else setEmail(undefined);
  };

  const handleChangePassword = (password: string | undefined) => {
    setPasswordError(undefined);
    setPassword(password);
  };

  const signUpUrl = () => {
    const ambassadorCode = searchParams?.["ambassadorCode"];
    const firstName = searchParams?.["firstName"];
    if (ambassadorCode && firstName) {
      return `/signup?ambassadorCode=${ambassadorCode}&firstName=${firstName}`;
    }
    return `/signup`;
  };

  const handleSubmitLogin = async () => {
    if (email && password) {
      try {
        const ambassadorCode = searchParams?.["ambassadorCode"] as string | undefined;
        setLoading(true);
        event("account_login_submitted", {
          email: email,
          referralProgram: ambassadorCode ? true : false
        });
        track("account_login_submitted", {
          email: email,
          referralProgram: ambassadorCode ? true : false
        });
        sendGTMEvent({ event: "account_login_submitted", email: email, referralProgram: ambassadorCode ? true : false });
        const data = await postSignIn(email, password, ambassadorCode ?? undefined);
        if (data.error) {
          event("account_login_error", {
            email: email,
            error: data.error.message
          });
          track("account_login_error", {
            email: email,
            error: data.error.message
          });
          sendGTMEvent({ event: "account_login_error", email: email, error: data.error.message });
          throw new Error(data.error.message);
        }
        if (!data.result) throw new Error("An error occurred. Please contact the support team.");
        if ("phoneNumber" in data.result) {
          // Handle MFA
          router.push("?state=mfa");
          props.setInfoUserMfa({
            email: email,
            challengeId: data.result.challengeId,
            phoneNumber: data.result.phoneNumber,
            ambassadorCode: ambassadorCode
          });
        } else {
          // Handle Login
          if (!data.result?.session.token) {
            throw new Error("An error occurred. Please contact the support team.");
          }
          setLocalStorageUserSession(data.result.session.token);
          setUserNameCookie(data.result.user);
          setAuthCookie(true);

          await updateDeviceData(data.result.session.token);

          try {
            await profile(data.result.user)
            await identify(data.result.user)
            event("account_login_completed", {
              email: email,
              referralProgram: ambassadorCode ? true : false
            })
            track("account_login_completed", {
              email: email,
              referralProgram: ambassadorCode ? true : false
            })
            sendGTMEvent({ event: "account_login_completed", email: email, referralProgram: ambassadorCode ? true : false });
          } catch (err) {
            console.warn(err)
          }

          logRocket.identify(data.result.user, true);

          if (data.result.user?.isBlacklisted && (data.result.user?.nbLoans || data.result.user?.hasActiveLoan)) {
            console.log("Going to /dashboard/instant-loan/active-loan");
            window.location.href = "/dashboard/instant-loan/active-loan";
          } else {
            console.log("Going to /dashboard");
            window.location.href = "/dashboard";
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          errorHandler?.report(`Receive ${e.message} error when login to maria with /user/login.`);
          setError(e.message);
        }
        setLoading(false);
      }
    }
  };

  const checkEmailFormat = (currentEmail: string | number | undefined) => {
    if (!currentEmail) {
      setEmailError(RequiredEmailErrorMessage);
    } else {
      currentEmail = currentEmail.toString();
      if (!isValidEmail(currentEmail)) {
        setEmailError(InvalidEmailErrorMessage);
      } else {
        setEmailError(undefined);
      }
    }
  };

  const checkPasswordFormat = (currentPassword: string | undefined) => {
    if (!currentPassword) setPasswordError(RequiredPasswordErrorMessage);
    else setPasswordError(undefined);
  };

  const isSubmitDisabled: boolean = !email || !password || emailError || passwordError ? true : false;

  return (
    <>
      <div
        className={`flex flex-col justify-between h-full py-[65px] px-[55px] md:px-[22px] sm:w-full max-w-[475px] md:max-w-none w-1/2 ${loading ? `self-center` : ``
          }`}
      >
        {loading ? (
          <Lottie animationData={iCashSymbolBlue} loop={true} />
        ) : (
          <div className="flex flex-col">
            <div className={error ? "mb-[33px]" : "mb-[3.25rem] md:mb-[33px]"}>
              <Subheading className="!p-0">Sign in</Subheading>
            </div>
            <div>
              {error ? (
                <ValidationMessage status="error" className="mb-4">
                  {error}
                </ValidationMessage>
              ) : null}
            </div>
            <form
              className="flex flex-col"
              onSubmit={(event) => {
                event.preventDefault();
              }}
            >
              <TextInput
                label="Email"
                onChange={handleChangeEmail}
                onBlur={() => checkEmailFormat(email)}
                value={email}
                inputMode="email"
                error={emailError}
              />
              <PasswordInput
                id="password"
                label="Password"
                onChange={handleChangePassword}
                value={password}
                onBlur={() => checkPasswordFormat(password)}
                error={passwordError}
              />
              <p className="caption-light !mb-[22px]">
                Forgot your password?{" "}
                <Link href="/forgot-password" className="text-primary hover:text-primary-emphasis !font-600">
                  Help me sign in
                </Link>
              </p>
              <div className="flex justify-end">
                <Button
                  disabled={isSubmitDisabled}
                  appearance="primary"
                  onClick={handleSubmitLogin}
                  className="!py-[13px] !text-[16px] button--large-disable-transition"
                >
                  Sign In
                </Button>
              </div>
            </form>
            <p className="caption-light !mt-[22px]">
              Don{"'"}t have an account yet?{" "}
              <Link href={signUpUrl()} className="text-primary hover:text-primary-emphasis !font-600">
                Create an account
              </Link>
            </p>
          </div>
        )}
      </div>
      {showNewPasswordModal && (
        <Modal
          onClose={() => {
            setShowNewPasswordModal(false);
          }}
          className="!min-h-min !min-w-min"
        >
          <div className="p-6 max-w-[420px] xs:max-w-xs">
            <h2 className="title-caption mb-6">Password changed</h2>
            <p className="caption-light whitespace-pre-line">You have modified your password successfully.</p>
          </div>
        </Modal>
      )}
      {showLinkHasExpiredModal && (
        <Modal
          onClose={() => {
            setShowLinkHasExpiredModal(false);
          }}
          className="!min-h-min !min-w-min"
        >
          <div className="p-6 max-w-[420px] xs:max-w-xs">
            <h2 className="title-caption mb-6">Link has expired</h2>
            <p className="caption-light whitespace-pre-line">This link has expired, please request a new reset password email.</p>
          </div>
        </Modal>
      )}
    </>
  );
};

const MFA_EVENTS = {
  loaded: {
    sms: "2fa_phone_validation_loaded",
    email: "2fa_email_validation_loaded"
  },
  submitted: {
    sms: "2fa_phone_validation_submitted",
    email: "2fa_email_validation_submitted"
  },
  completed: {
    sms: "2fa_phone_validation_completed",
    email: "2fa_email_validation_completed"
  },
  error: {
    sms: "2fa_phone_validation_pin_error",
    email: "2fa_email_validation_pin_error"
  },
  resend: {
    sms: "2fa_resend_phone_pin_clicked",
    email: "2fa_resend_email_pin_clicked"
  },
  resend_error: {
    sms: "2fa_resend_phone_pin_error",
    email: "2fa_resend_email_pin_error"
  },
  sing_in_another_way: {
    sms: "2fa_phone_signin_clicked",
    email: "2fa_email_signin_clicked"
  },
  sing_in_another_way_confirm: {
    sms: "2fa_phone_signin_confirmation_clicked",
    email: "2fa_email_signin_confirmation_clicked"
  },
  logout: {
    sms: "2fa_phone_validation_logout_clicked",
    email: "2fa_email_validation_logout_clicked"
  }
}

const MFAConfirmation = (props: MFAConfirmationProps) => {
  const [isValidOtp, setIsValidOtp] = React.useState<boolean>();
  const [otp, setOtp] = React.useState<string>();
  const [isOpenContactModal, setIsOpenContactModal] = React.useState<boolean>(false);
  const [isOpenModalVerification, setIsOpenModalVerification] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<ConfigMessageMFA | undefined>();
  const logRocket = useLogRocket();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [methodMFA, setMethodMFA] = React.useState<MFAType>("sms");
  const { event, profile, screen } = useClevertapAnalytics();
  const { page, track } = useSingularAnalytics();

  React.useEffect(() => {
    if (MFA_EVENTS.loaded[methodMFA]) {
      screen(MFA_EVENTS.loaded[methodMFA], { category: '2fa' });
      page('2fa', MFA_EVENTS.loaded[methodMFA]);
    }

  }, [methodMFA]);


  const verifyCode = async (otp: string) => {
    try {
      setLoading(true);
      if (MFA_EVENTS.submitted[methodMFA]) {
        event(MFA_EVENTS.submitted[methodMFA], { email: props.infoUserMFA?.email });
        track(MFA_EVENTS.submitted[methodMFA], { email: props.infoUserMFA?.email });
        sendGTMEvent({ event: MFA_EVENTS.submitted[methodMFA], email: props.infoUserMFA?.email });
      }
      const data = await postConfirmMFALogin(props.infoUserMFA.challengeId, otp);
      if (data.error) {
        setIsValidOtp(false);
        if (data.error.code == 444) {
          throw new Error(configErrorMFA.invalid.message);
        } else if (data.error.code == 404) {
          throw new Error(configErrorMFA.expired.message);
        } else if (data.error.code == 429) {
          throw new Error(configErrorMFA.maxAttempts.message);
        }
        throw new Error(data.error.message);
      }
      setIsValidOtp(true);
      if (!data.result?.session.token) {
        throw new Error("An error occurred. Please contact the support team.");
      }
      setLocalStorageUserSession(data.result.session.token);
      setUserNameCookie(data.result.user);
      setAuthCookie(true);
      await updateDeviceData(data.result.session.token);
      logRocket.identify(data.result.user, true);
      profile(data.result.user);

      if (MFA_EVENTS.completed[methodMFA]) {
        event(MFA_EVENTS.completed[methodMFA], { email: props.infoUserMFA?.email });
        track(MFA_EVENTS.completed[methodMFA], { email: props.infoUserMFA?.email });
        sendGTMEvent({ event: MFA_EVENTS.completed[methodMFA], email: props.infoUserMFA?.email });
      }

      if (data.result.user?.isBlacklisted && (data.result.user?.nbLoans || data.result.user?.hasActiveLoan)) {
        console.log("Going to /dashboard/instant-loan/active-loan");
        window.location.href = "/dashboard/instant-loan/active-loan";
      } else {
        console.log("Going to /dashboard");
        window.location.href = "/dashboard";
      }
    } catch (e) {
      setLoading(false);
      if (MFA_EVENTS.error[methodMFA]) {
        event(MFA_EVENTS.error[methodMFA], { email: props.infoUserMFA?.email, phone: props.infoUserMFA?.phoneNumber });
        track(MFA_EVENTS.error[methodMFA], { email: props.infoUserMFA?.email, phone: props.infoUserMFA?.phoneNumber });
        sendGTMEvent({ event: MFA_EVENTS.error[methodMFA], email: props.infoUserMFA?.email, phone: props.infoUserMFA?.phoneNumber });
      }
      if (e instanceof Error) {
        errorHandler?.report(`Receive ${e.message} error when login to maria with /user/login or /user/loginconfirm.`);
        if (e.message !== configErrorMFA.invalid.message) {
          setMessage({
            type: "error",
            message: e.message
          });
        }
      }
    }
  };

  const onResendCode = async (type: MFAType, displayToast: boolean = true) => {
    try {
      setLoading(true);
      const data =
        type === "sms"
          ? await postResendMFASms(props.infoUserMFA.challengeId)
          : await postResendMFAEmail(props.infoUserMFA.challengeId);
      if (data.error?.code === 404) {
        // Redirect to login if challenge is expired, user must login
        setLoading(false);
        props.setInfoUserMfa(undefined);
      } else if (data.error?.code == 429) {
        throw new Error(configErrorMFA.maxAttempts.message);
      } else if (data.result?.challengeId) {
        if (displayToast) {
          setMessage(type === "sms" ? configErrorMFA.resendSMS : configErrorMFA.resendEmail);
        } else {
          setMessage(undefined);
        }
        setMethodMFA(type);
        props.setInfoUserMfa({
          ...props.infoUserMFA,
          challengeId: data.result?.challengeId
        });
        setLoading(false);
      } else {
        throw new Error(data.error?.message ?? configErrorMFA.unknown.message);
      }
    } catch (e) {
      setLoading(false);

      if (e instanceof Error) {
        if (MFA_EVENTS.resend_error[methodMFA]) {
          event(MFA_EVENTS.resend_error[methodMFA], {
            error: e.message,
            email: props.infoUserMFA?.email,
            phone: props.infoUserMFA?.phoneNumber
          });
          track(MFA_EVENTS.resend_error[methodMFA], {
            error: e.message,
            email: props.infoUserMFA?.email,
            phone: props.infoUserMFA?.phoneNumber
          });
          sendGTMEvent({ event: MFA_EVENTS.resend_error[methodMFA], error: e.message, email: props.infoUserMFA?.email, phone: props.infoUserMFA?.phoneNumber });
        }

        errorHandler?.report(`Receive ${e.message} error when resend code to maria with MFA /loginresendchallenge.`);
        setMessage({
          type: "error",
          message: e.message
        });
      }
    }
  };
  return (
    <div className="flex flex-col justify-between h-full py-[65px] px-[55px] md:px-[22px] sm:w-full max-w-[475px] md:max-w-none">
      <Subheading className="!p-0 !mb-16 !text-[28px] sm:!text-[21px] !font-500">Sign in</Subheading>
      {message && !isValidOtp ? (
        <ValidationMessage status={message.type} className="mb-8">
          {message.message}
        </ValidationMessage>
      ) : null}
      <div className="gap-8 flex flex-col">
        <p className="caption-light">
          To continue, please enter the 6-digit code that was sent to{" "}
          <span className="subtitle-light-no-color">
            {methodMFA === "sms" ? props.infoUserMFA?.phoneNumber : props.infoUserMFA?.email}.
          </span>
        </p>
        <div className={`flex justify-center ${isValidOtp === false ? "mb-4" : ""}`}>
          <PinInputV2
            length={6}
            value={otp}
            onChange={setOtp}
            onComplete={(newCode: string) => {
              verifyCode(newCode);
            }}
            valid={isValidOtp}
            onReset={() => {
              setIsValidOtp(undefined);
            }}
            error={isValidOtp === false ? "Invalid code" : ""}
          />
        </div>
        <div>
          <p className="caption-medium mb-2">
            Didn{"'"}t get a code?{" "}
            <span className="global--link !font-600" onClick={() => {
              if (MFA_EVENTS.resend[methodMFA]) {
                event(MFA_EVENTS.resend[methodMFA]);
                track(MFA_EVENTS.resend[methodMFA]);
                sendGTMEvent({ event: MFA_EVENTS.resend[methodMFA] });
              }
              onResendCode(methodMFA)
            }}>
              Resend
            </span>{" "}
            code or{" "}
            <span
              className="global--link !font-600"
              onClick={() => {
                setIsOpenContactModal(true);
              }}
            >
              contact
            </span>{" "}
            our support team.
          </p>
          <p className="caption" onClick={() => setIsOpenModalVerification(true)}>
            Having trouble? <span className="global--link !font-600">Sign in another way.</span>
          </p>
        </div>
        <Button
          onClick={() => {
            if (MFA_EVENTS.logout[methodMFA]) {
              event(MFA_EVENTS.logout[methodMFA]);
              track(MFA_EVENTS.logout[methodMFA]);
              sendGTMEvent({ event: MFA_EVENTS.logout[methodMFA] });
            }
            props.setInfoUserMfa(undefined);
          }}
          disabled={loading}
          loading={loading}
          className="!text-[16px] !py-[13px]"
        >
          Log out
        </Button>
      </div>
      <SupportPanel
        isOpen={isOpenContactModal}
        handleOpen={() => { }}
        handleClose={() => {
          setIsOpenContactModal(false);
        }}
      />
      {isOpenModalVerification ? (
        methodMFA === "sms" ? (
          <ModalEmailVerification
            onClose={() => setIsOpenModalVerification(false)}
            onResend={() => onResendCode("email", false)}
            infoUserMFA={props.infoUserMFA}
          />
        ) : (
          <ModalPhoneVerification
            onClose={() => setIsOpenModalVerification(false)}
            onResend={() => onResendCode("sms", false)}
            infoUserMFA={props.infoUserMFA}
          />
        )
      ) : null}
    </div>
  );
};
const ModalEmailVerification = (props: ModaleVerificationProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { event } = useClevertapAnalytics();
  const { track } = useSingularAnalytics();

  React.useEffect(() => {
    if (MFA_EVENTS.sing_in_another_way['email']) {
      event(MFA_EVENTS.sing_in_another_way['email']);
      track(MFA_EVENTS.sing_in_another_way['email']);
      sendGTMEvent({ event: MFA_EVENTS.sing_in_another_way['email'] });
    };
  }, []);

  return (
    <Modal onClose={props.onClose} className="!min-h-min sm:w-[90%]">
      <div className="modal--content !p-6 max-w-[610px]">
        <h2 className="title-caption mb-[22px] sm:mb-[11px]">Email verification</h2>
        <p className="caption-light mb-[10px] whitespace-pre-line">
          To access your account, please enter the verification code that will be sent to{" "} <span className="font-600">{props.infoUserMFA?.email}</span>.
        </p>
        <div className="flex gap-4 mt-6 sm:flex-col sm:items-center">
          <Button className="w-1/2 sm:w-full !text-[16px] !py-[13px]" onClick={props.onClose}>
            Cancel
          </Button>
          <Button
            className="w-1/2 sm:w-full !text-[16px] !py-[13px]"
            onClick={async () => {
              setLoading(true);
              if (MFA_EVENTS.sing_in_another_way_confirm["email"]) {
                event(MFA_EVENTS.sing_in_another_way_confirm["email"]);
                track(MFA_EVENTS.sing_in_another_way_confirm["email"]);
                sendGTMEvent({ event: MFA_EVENTS.sing_in_another_way_confirm["email"] });
              }
              await props.onResend();
              setLoading(false);
              props.onClose();
            }}
            appearance="primary"
            loading={loading}
          >
            Send verification email
          </Button>
        </div>
      </div>
    </Modal>
  );
};
const ModalPhoneVerification = (props: ModaleVerificationProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { event } = useClevertapAnalytics();
  const { track } = useSingularAnalytics();

  React.useEffect(() => {
    if (MFA_EVENTS.sing_in_another_way['sms']) {
      event(MFA_EVENTS.sing_in_another_way['sms']);
      track(MFA_EVENTS.sing_in_another_way['sms']);
      sendGTMEvent({ event: MFA_EVENTS.sing_in_another_way['sms'] });
    }
  }, []);

  return (
    <Modal onClose={props.onClose} className="!min-h-min sm:w-[90%]">
      <div className="modal--content !p-6 max-w-[610px]">
        <h2 className="title-caption mb-[22px] sm:mb-[11px]">Phone verification</h2>
        <p className="caption-light mb-[10px] whitespace-pre-line">
          To access your account, please enter the verification code that will be sent to{" "}
          <span className="font-600">{props.infoUserMFA?.phoneNumber}</span>.
        </p>
        <div className="flex gap-4 mt-6 sm:flex-col sm:items-center">
          <Button className="w-1/2 sm:w-full !text-[16px] !py-[13px]" onClick={props.onClose}>
            Cancel
          </Button>
          <Button
            className="w-1/2 sm:w-full !text-[16px] !py-[13px]"
            onClick={async () => {
              setLoading(true);
              if (MFA_EVENTS.sing_in_another_way_confirm["sms"]) {
                event(MFA_EVENTS.sing_in_another_way_confirm["sms"]);
                track(MFA_EVENTS.sing_in_another_way_confirm["sms"]);
                sendGTMEvent({ event: MFA_EVENTS.sing_in_another_way_confirm["sms"] });
              }
              await props.onResend();
              setLoading(false);
              props.onClose();
            }}
            appearance="primary"
            loading={loading}
          >
            Send verification SMS
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default LoginComponent;
