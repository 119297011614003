import React from "react";
import { InstantLoanActionType, InstantLoanContext } from "@helpers/context/instantLoan";
import { useRouter } from "next/navigation";
import { mariaFetch } from "@helpers/requests/maria-fetch";
import { getUserSessionId } from "@helpers/storages/auth";
import { errorHandler } from "@helpers/stackDriver";

export const useInstantLoanHooks = (parentDispatch?: React.Dispatch<InstantLoanActionType>, disableFetch?: boolean) => {
  const [error, setError] = React.useState<string>();
  const router = useRouter();
  const { state, dispatch } = React.useContext(InstantLoanContext);

  const onFetchData = async () => {
    try {
      if (disableFetch === true) {
        return;
      }
      const response = await mariaFetch(
        "/user/dashboard/instantloan",
        {
          headers: {
            "Application-ID": process.env.NEXT_PUBLIC_MARIA_APPLICATION_ID,
            "Content-Type": "application/json",
            Authorization: `Bearer ${getUserSessionId()}`
          }
        },
        router
      );

      const data = await response.json();
      if (data.error) {
        throw new Error(`Error code ${data.error.code}: ${data.error.message}`);
      }
      if (parentDispatch) {
        parentDispatch({ type: "setInstantLoan", value: data.result });
      } else {
        dispatch({ type: "setInstantLoan", value: data.result });
      }
    } catch (e: any) {
      if (e instanceof Error) {
        errorHandler?.report(
          `Receive error : ${e.message} when fetching dashboard instantloan to maria with /user/dashboard/instantloan.`
        );
      }

      setError("Failed to load, attempt a refresh to continue");
    }
  };

  React.useEffect(() => {
    onFetchData();
  }, []);

  return { state, error, dispatch, update: onFetchData };
};
