export const Subheading = (props: { children: JSX.Element | string; className?: string }) => {
  return (
    <h3
      className={`text-[37px] md:text-[30px] sm:text-[21px] leading-tight font-600 sm:py-[22px] py-[44px] pb-[37px] ${
        props.className ?? ""
      }`}
    >
      {props.children}
    </h3>
  );
};
