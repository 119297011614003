"use client";

import { SearchParamsContext } from "@helpers/context/searchParams";
import { useSearchParams } from "next/navigation";
import React from "react";

const SearchQueryProvider = () => {
  const search = useSearchParams();
  const { dispatch } = React.useContext(SearchParamsContext);

  React.useEffect(() => {
    const dict: { [key: string]: string } = {};
    search.forEach((value, key) => {
      dict[key] = value;
    });
    dispatch({
      searchParams: dict,
      type: "setSearchParams"
    });
  }, [search]);
  return <></>;
};

export default SearchQueryProvider;
